



export default function ZonalOfficeCard({zonalOfficeName, address, contactName, phoneNumber}) {

    return(
        <div className="zonal-office-card py-3">
            <div className="zonal-info">
                <h5>{zonalOfficeName}</h5>

                <div className="address px-4">
                    <span>Address: </span>
                    <p>{address}</p>
                </div>

                <div className="contact-person px-4">
                    <span>Contact Person:</span>
                    <p>{contactName}  {phoneNumber}</p>
                </div>
            </div>
        </div>
    )
}