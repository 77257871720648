import {
    useLocation
} from "react-router-dom";
import zonalOffices from '../../components/data/ZonalOfficesData';
import lgaOffices from '../../components/data/LgaOfficeData';
import ZonalOfficeCard from "../../components/reusable/ZonalOfficeCard";
import fintiriPhoto from '../../assets/fintiri.webp';
import Commissioner from '../../assets/team/Commissioner.webp';
import zainab from '../../assets/team/Zainab-Idi-Bode.webp';
import './about.css'
import TeamCard from "../../components/reusable/TeamCard";
import teamMembers from '../../components/data/TeamData'
import ReusableHero from "../../components/reusable/Hero";

export default function AboutUs() {

    let location = useLocation();
    return (
        <>
            {/* <ReusableHero /> */}
            <div className="about-page">

                {
                    location.pathname === "/about-us/team" &&
                    <div className="d-flex justify-content-center  py-5">
                        <div className="px-5 mx-5 team-members ">
                            <h1>
                                Meet Our Team
                            </h1>
                            <p className="team-p">Our team is a tapestry of talent, weaving together creativity, expertise, and dedication. From the strategists shaping our vision to the problem-solvers implementing it, we're a family of professionals committed to excellence. Get to know the faces behind the magic, as each member of our team plays an integral role in crafting the success story of Adamawa State Department of Cooperatives.</p>

                            <div className="d-flex flex-column justify-content-center align-items-center team-gallery mt-5">
                                <div className="team-card ">
                                    <div className="team-photo  mx-auto big">
                                        <img src={fintiriPhoto} alt="" />
                                    </div>
                                    <p className="team-info">
                                        RT. Hon. Ahmadu Umaru Fintiri
                                        {<br />}
                                        <span>Governor Adamawa State (President In Council)</span>
                                    </p>
                                </div>



                                <div className="d-flex my-5 medium-size-card">
                                    <div className="team-card mx-4 ">
                                        <div className="team-photo mx-auto medium">
                                            <img src={Commissioner} alt="" />
                                        </div>
                                        <p className="team-info ">
                                            James Illiya
                                            {<br />}
                                            <span>Commissioner Entrepreneurship {<br />} and Development </span>
                                        </p>
                                    </div>

                                    <div className="team-card mx-4 ">
                                        <div className="team-photo mx-auto medium">
                                            <img src={zainab} alt="" />
                                        </div>
                                        <p className="team-info">
                                            Zainab Idi Bode
                                            {<br />}
                                            <span>State Director</span>
                                        </p>
                                    </div>
                                </div>




                                <div className="mt-5 ">
                                    <div className="team-list">
                                        {teamMembers.map((team, index) => (
                                            <TeamCard
                                                key={index}
                                                photoSrc={team.photoSrc}
                                                teamName={team.teamName}
                                                title={team.title}
                                                className={index >= 8 ? "centered" : ""}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    location.pathname === "/about-us/zonal-offices" &&

                    <>
                        <div className="mt-5">
                            <h1> Zonal Operational Offices    </h1>
                            <div className="d-flex justify-content-center mb-5">
                                <div className="zonal-office-list">

                                    {zonalOffices.map((office, index) => (
                                        <ZonalOfficeCard
                                            key={index}
                                            zonalOfficeName={office.zonalName}
                                            address={office.zonalOffice}
                                            contactName={office.contactPerson}
                                            phoneNumber={office.phoneNumber}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="mt-5">
                            <h1> Local Government Area Operational Offices    </h1>
                            <div className="d-flex justify-content-center my-2">
                                <div className="zonal-office-list">

                                    {lgaOffices.map((lga, index) => (
                                        <ZonalOfficeCard
                                            key={index}
                                            zonalOfficeName={lga.lgaName}
                                            contactName={lga.contactPerson}
                                            phoneNumber={lga.phoneNumber}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>






                }
            </div>
        </>

    )
}