
import {
    useLocation
} from "react-router-dom";
import './reusable.css'


export default function ReusableHero() {
    let location = useLocation();

    let backgroundImage;

    if (location.pathname === "/") {
        backgroundImage = "var(--background-home)";
    } else if (location.pathname === "/home") {
        backgroundImage = "var(--background-home)";
    } else if (location.pathname === "/about-us/team" || location.pathname === "/about-us/zonal-offices" ) {
        backgroundImage = "var(--background-aboutus)";
    }
    else if (location.pathname === "/publication") {
        backgroundImage = "var(--background-publication)";
    }
    else if (location.pathname === "/registration") {
        backgroundImage = "var(--background-registration)";
    } else if (location.pathname === "/faqs") {
        backgroundImage = "var(--background-faq)";
    }
    // Add more conditions for other routes as needed

    const heroStyle = {
        backgroundImage: `linear-gradient(90deg, rgba(1, 28, 3, 0.9), rgba(1, 28, 3, 0.9)), ${backgroundImage}`,
    };

    return (
        <div className="d-flex align-items-center justify-content-center hero-bg p-0" style={heroStyle}>
            <div className=" hero-content py-2">

                {
                    (location.pathname === "/" ||
                        location.pathname === "/home") &&
                    (<h1>
                        Welcome To Adamawa State
                        {<br />}
                        Department Of Cooperatives {<br />}
                        <span>
                            Official Website
                        </span>
                    </h1>
                    )
                }

                {
                    location.pathname === "/about-us/team" &&
                    <h1>
                        <span>
                            Meet Our Team
                        </span>
                        {<br />}
                        Adamawa State Department
                        {<br />}
                        Of Cooperatives

                    </h1>
                }
                {
                    location.pathname === "/about-us/zonal-offices" &&
                    <h1>
                        Adamawa State Department

                        {<br />}
                        Of Cooperatives
                        {<br />}<span>
                            Zonal Operational Offices
                        </span>




                    </h1>
                }

                {
                    location.pathname === "/registration" &&
                    <h1>
                        Adamawa State Department
                        {<br />}

                        Of Cooperatives {<br />}
                        <span>

                            Cooperative Registration
                        </span>
                    </h1>
                }
                {
                    location.pathname === "/publication" &&
                    <h1>
                        Adamawa State Department

                        {<br />}
                        Of Cooperatives {<br />}
                        <span>
                            Publications
                        </span>
                    </h1>
                }

                {
                    location.pathname === "/faqs" &&
                    <h1>
                        Frequently Asked Questions

                    </h1>
                }

            </div>
        </div>
    )
}