import wase from '../../assets/team/Wase-Bulus.webp';
import hapsatu from '../../assets/team/Hapsatu-Abubakar.webp';
import aminchi from '../../assets/team/Aminichi-E.-Kefas.webp';
import adamau from '../../assets/team/Adamu-B.-Maji.webp';
import hanabina from '../../assets/team/Hanabina-Shadrach-Audit.webp';
import laraba from '../../assets/team/Laraba-Haziel.webp';
import margret from '../../assets/team/Margret-Simon-Tizhe.webp';
import hanatu from '../../assets/team/Hanatu-Emmanuel-Coperative-Development.webp';
import ambrose from '../../assets/team/Ambrose-Abasu-ICT-1.webp';
import mohammed from '../../assets/team/Mohammad-IbrahimBelel-ICT-2.webp';



const teamData = [
   
    {
        photoSrc: wase,
        teamName: "Wase Bulus Filli",
        title: "Head of Field Services Unit",
    },
    {
        photoSrc: hapsatu,
        teamName: "Hapsatu Abubakar",
        title: "Head of Registration Unit",
    },
    {
        photoSrc: aminchi,
        teamName: "Aminchi Kefas",
        title: "Head of Agric Unit",
    },
    {
        photoSrc: adamau,
        teamName: "Adamu Maji",
        title: "Head of Education Unit",
    },

    {
        photoSrc: hanabina,
        teamName: "Hanabina Shadrach",
        title: "Head of Audit Unit",
    },

    {
        photoSrc: laraba,
        teamName: "Laraba Haziel",
        title: "Head of Marketing Unit",
    },
    {
        photoSrc: margret,
        teamName: "Margret Simon Tizhe",
        title: "Head of Informal Unit",
    },
    {
        photoSrc: hanatu,
        teamName: "Hanatu Emmanuel",
        title: "Head of Cooperative Development Unit",
    },

    {
        photoSrc: ambrose,
        teamName: "Ambrose Abasu",
        title: "Head of ICT Unit 1",
    },

    {
        photoSrc: mohammed,
        teamName: "Mohammad Ibrahim",
        title: "Head of ICT Unit 2",
    },

];

export default teamData;