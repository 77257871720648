import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// import './SinglePublicationPage.css'; // You can create the CSS file for this component


function SinglePublicationPage() {
    const location = useLocation();
    const { articleId } = useParams();
    const [selectedArticle, setSelectedArticle] = useState(null);
  
    const newsData = location.state.newsData; // Access the passed news data
  
    useEffect(() => {
      if (newsData) {
        const article = newsData[articleId];
        setSelectedArticle(article);
      }
    }, [articleId, newsData]);
  
    if (!selectedArticle) {
      return <div>Loading...</div>;
    }

    return (
        <div className="single-publication-page">
            <div className="selected-article-image">
                <img src={selectedArticle.image_url} alt={selectedArticle.title} />
            </div>
            <div className="selected-article-content">
                <h2>{selectedArticle.title}</h2>
                <p>{selectedArticle.description}</p>
            </div>
            {/* Related news section */}
            {/* You can display related news here */}
        </div>
    );
}

export default SinglePublicationPage;
