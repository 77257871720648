
const lgaOffices = [
    {
      lgaName: "Yola South LGA Office",
      contactPerson: "Hapsatu Dahiru",
      phoneNumber: "08036471221",
    },
    {
      lgaName: "Yola North LGA Office",
      contactPerson: "Ibrahim Hamman",
      phoneNumber: "08034228451",
    },
    {
      lgaName: "Fufore LGA Office",
      contactPerson: "Idris Abdullahi",
      phoneNumber: "08069129681",
    },
    {
      lgaName: "Girei LGA Office",
      contactPerson: "Abubakar David Esthon",
      phoneNumber: "07061335282",
    },
    {
      lgaName: "Mubi North LGA Office",
      contactPerson: "Cletus Apagu",
      phoneNumber: "08064589188",
    },
    {
      lgaName: "Mubi South LGA Office",
      contactPerson: "Ishaku Abdullahi",
      phoneNumber: "07068734546",
    },
    {
      lgaName: "Maiha LGA Office",
      contactPerson: "Mathias Naya Abubakar",
      phoneNumber: "",
    },
    {
      lgaName: "Madagali LGA Office",
      contactPerson: "Ijabula Jingi",
      phoneNumber: "0806874646",
    },
    {
      lgaName: "Michika LGA Office",
      contactPerson: "Mustapha Abbas",
      phoneNumber: "",
    },
    {
      lgaName: "Hong LGA Office",
      contactPerson: "Suleiman Ahmad",
      phoneNumber: "07036671754",
    },
    {
      lgaName: "Gombi LGA Office",
      contactPerson: "Suleiman Dalhatu",
      phoneNumber: "08034228451",
    },
    {
      lgaName: "Song LGA Office",
      contactPerson: "Shehu Mohammed",
      phoneNumber: "08069129681",
    },
    {
      lgaName: "Demsa LGA Office",
      contactPerson: "Emma Peter K",
      phoneNumber: "08139678164",
    },
    {
      lgaName: "Shelleng LGA Office",
      contactPerson: "Ramatu Zakaria Yawale",
      phoneNumber: "08034228451",
    },
    {
      lgaName: "Lamurde LGA Office",
      contactPerson: " Pwanabeshi Hasley",
      phoneNumber: "08162157912",
    },
    {
      lgaName: "Guyuk LGA Office",
      contactPerson: "Margret Amun P",
      phoneNumber: "08164118047",
    },
    {
      lgaName: "Numan LGA Office",
      contactPerson: "Pwamori Mbebo Allen",
      phoneNumber: "08067770877",
    },
    {
      lgaName: "Ganye LGA Office",
      contactPerson: "Adiel Dogo",
      phoneNumber: "07033467734",
    },
    {
      lgaName: "Toungo LGA Office",
      contactPerson: "Christiana Ajayi",
      phoneNumber: "08024870049",
    },
    {
      lgaName: "Jada LGA Office",
      contactPerson: "Felix Hananiya",
      phoneNumber: "08059844390",
    },
    {
      lgaName: "Mayo Belwa LGA Office",
      contactPerson: "Taasodugi Evans",
      phoneNumber: "08065100705",
    },

];

export default lgaOffices;