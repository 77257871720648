
import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@iconify/react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import emailjs from 'emailjs-com';

import './home.css';
import { useLocation } from 'react-router-dom';



export default function LandingPage() {

    const { pathname } = useLocation();
    const contactSectionRef = useRef(null);

    useEffect(() => {
        if (pathname === '/home' && contactSectionRef.current) {
            contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [pathname]);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_22a5pr2', 'template_5c8qex2', e.target, 'Yun93fprX7QbpcrHH')
            .then(
                (result) => {
                    console.log('Email sent successfully:', result.text);
                    // Add any success handling code here (e.g., show a success message).

                    setFormData({ name: '', email: '', message: '' });

                    // Show the Snackbar to indicate successful submission
                    setSnackbarOpen(true);
                },
                (error) => {
                    console.error('Email could not be sent:', error.text);
                    // Add error handling code here (e.g., show an error message).
                }
            );

        // Clear the form fields after submission (optional).
        e.target.reset();
    };



    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            {/* <ReusableHero /> */}
            <div className="landing-page">
                <section className=' p-5 my-5'>
                    <div className="about-us-section">
                        <h1 className=''>
                            About Us
                        </h1>
                        <p>
                            The Adamawa State Department of Cooperatives (ASCP) is a Government establishment charged with the responsibility of mobilizing individuals, usually of limited means to register Cooperative Societies by voluntarily coming together to achieve a common economic end.
                            {<br />}
                            Cooperative in Adamawa State has been in existence from time immemorial, that is; it is  synonymous to the history of our people. Traditionally, cooperative is being practiced in every part of our State, west, east, south and north.
                        </p>
                    </div>
                </section>

                <section className=''>
                    <div className="container-fluid d-flex align-items-center justify-content-center mission-vision-section  ">
                        <div className="mission-vision-content d-flex justify-content-around my-4">
                            <div className="mission">
                                <div className='bordered-heading'>
                                    <h2 >Our Mission</h2>
                                </div>
                                

                                <ul>
                                    <li>
                                        To organize programs that foster unity, knowledge acquisition and welfare of members.
                                    </li>
                                    <li>
                                        To represent cooperative sector in dialogue with Government for favorable legislation.
                                    </li>
                                    <li>
                                        To attract development partners to the cooperative movement in Adamawa State.
                                    </li>
                                </ul>
                            </div>
                            <div className="vision">
                                <h2>Our Vision</h2>
                                <p>To be foremost non governmental organizations for economic empowerment and prosperity of Adamawa State.</p>
                            </div>
                        </div>

                    </div>

                </section>

                <section className=' p-5 my-3'>
                    <div className="registration-section">

                        <h1 className=''>
                            Registration Flow

                        </h1>
                        <ul className='px-3'>
                            <li>
                                Cooperative Inspectors visit communities to create awareness about formation of cooperative societies.
                            </li>
                            <li>

                                When the idea of cooperative is accepted by the people, they are then encouraged to form themselves into cooperative societies.
                            </li>
                            <li>
                                They meet to elect their officials under the supervision of the cooperative inspector.
                            </li>
                            <li>
                                Cooperative Inspector writes reports (A, B, C) on the proposed cooperative society.
                            </li>
                            <li>
                                After several visits to the society, the inspector recommends them to the Area Officer for registration.

                            </li>
                            <li>
                                The Area Officer forwards same to the Director of Cooperatives for registration.

                            </li>
                            <li>
                                When the Director is satisfied with the recommendations for registration, a duly signed Certificate of Registration is issued to them.
                            </li>
                        </ul>

                        <button className="register-btn btn ">
                            Learn More
                        </button>
                    </div>

                </section>


                <section ref={contactSectionRef} id="contact">
                    <div className="contact-us-section p-5" >
                        <div className="">
                            <h1 className=''>
                                CONTACT US
                            </h1>

                            <div className="d-flex align-items-center justify-content-between mt-5 contact-content">
                                <div className="contact-form">
                                    <p>Send us a message</p>
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <div className="d-flex justify-content-between form-area">
                                                <input type="text" name="name" value={formData.name}
                                                    onChange={handleChange} placeholder='Your Full Name' />
                                                <input type="email" name="email" value={formData.email}
                                                    onChange={handleChange} placeholder='Your Email Address' />
                                            </div>
                                            <textarea name="message" value={formData.message}
                                                onChange={handleChange} cols="30" rows="10" placeholder='Type your message' className='textarea' />

                                        </div>

                                        <button className="register-btn btn" type="submit">
                                            Send Message
                                        </button>

                                    </form>

                                </div>

                                <div className="vertical-hr">
                                    <div className="red-line"></div>
                                    <div className="blue-line"></div>
                                    <div className="red-line"></div>
                                    <div className="blue-line"></div>
                                    <div className="red-line"></div>
                                </div>

                                <div className="contact-info px-3 py-4">
                                    <div className="contact-links">

                                        <p> <Icon icon="fluent:call-24-filled" /> Call Us {<br />} <a href="tel:+2348146205677">+234 (0)8146205677</a></p>
                                        <p><Icon icon="material-symbols:mail-rounded" /> Email Us {<br />} <a href="mailto: adamawacoop@gmail.com">adamawacoop@gmail.com</a></p>
                                        <p><Icon icon="material-symbols:location-on" /> Visit Us {<br />}
                                            <span>Ministry For Entrepreneurship Development, Office of the Deputy Governor, No. 1 Adamawa Road, off Kashim Ibrahim Way, P.M.B 2097, Jimeta Yola, Adamawa State</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={handleCloseSnackbar}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleCloseSnackbar}
                        severity="success" // Change severity to 'error' for error messages
                    >
                        Message sent successfully!
                    </MuiAlert>
                </Snackbar>
            </div>
        </>


    )
}