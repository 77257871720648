import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import CooperativeApplicationForm from '../AD-form.pdf';


export default function MultiPurposeRegistration() {


    const navigate = useNavigate();


    const handleDownloadClick = () => {
        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = CooperativeApplicationForm;
        anchor.download = 'adamawa-cooperative-application-form.pdf';

        // Trigger a click event on the anchor element
        const clickEvent = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        anchor.dispatchEvent(clickEvent);

        // Clean up the temporary anchor element
        window.URL.revokeObjectURL(anchor.href);
    };



    return (
        <div className=" d-flex justify-content-center multi-purpose px-4 py-3">
            <div className="multi-purpose-content">


                <div className="types">
                    <h1 className="reg-title">TYPES OF COOPERATIVE SOCIETY</h1>
                    <div>
                        <div className="single">
                            <h1>Single Purpose Cooperative Society</h1>
                            <p>A Single Purpose Cooperative Society is a type of cooperative organization formed by individuals with a specific common goal or purpose. Unlike general-purpose cooperatives that serve a wide range of needs, a single-purpose cooperative focuses exclusively on a particular activity, product, or service. </p>
                        </div>
                        <div className="single">
                            <h1>Multi-purpose Cooperative Society</h1>
                            <p>A Multi-purpose Cooperative Society is a group of people who come together to collectively achieve a specific goal or purpose, such as sharing resources, conducting a particular business activity, or providing a particular service, while benefiting all members involved.</p>
                        </div>
                    </div>
                </div>



                <div className="mt-5">
                    <h1>REGISTRATION STEPS</h1>
                    <div className="steps mt-3">

                        <div className="steps-card">
                            <span>01</span>
                            <h6>Qualify For Application</h6>
                            <p>Review the registration eligibility criteria and necessary document prerequisites provided beneath to confirm your qualification for application</p>
                        </div>

                        <div className="vertical-hr">
                            <div className="red-line"></div>
                            <div className="blue-line"></div>
                            <div className="red-line"></div>
                            <div className="blue-line"></div>
                            <div className="red-line"></div>
                        </div>

                        <div className="steps-card">
                            <span>02</span>
                            <h6>Download Form</h6>
                            <p>Download the cooperatives registration form below using the “download here" button below.</p>
                        </div>

                        <div className="vertical-hr">
                            <div className="red-line"></div>
                            <div className="blue-line"></div>
                            <div className="red-line"></div>
                            <div className="blue-line"></div>
                            <div className="red-line"></div>
                        </div>

                        <div className="steps-card">
                            <span>03</span>
                            <h6>Fill & Submit</h6>
                            <p>Print the form, complete it with precision to the best of your capabilities, and then submit it to our office. You can locate our office address by <a href="mailto:">clicking here</a> </p>
                        </div>

                    </div>
                </div>


                <div className="registration-eligibility mt-5">
                    <h2>ELIGIBILITY FOR REGISTRATION</h2>
                    <p>To qualify for registration as a Cooperative Society, there should be at least ten (10) members. List of members and the name of the cooperative society. Address and location of the Society. Phone numbers of the President and Secretary of the cooperative society are to be included.
                        (ii) Aims and Objectives of the proposed Cooperative Society.</p>
                </div>

                <div className="registration-requirement mt-5">
                    <h2>DOCUMENTS REQUIRED FOR REGISTRATION</h2>
                    <div>
                        <p>(A)  An application for registration containing the following:-</p>
                        <ol class="roman-list">
                            <li>Name of the Society</li>
                            <li> Address</li>
                            <li> Area of Operation</li>
                            <li> Objectives of the Society</li>
                            <li>Number of Shares and Value</li>
                            <li>Qualification for Membership</li>
                            <li>Brief description of the proposed working of the Society and names of President and Secretary</li>
                        </ol>
                    </div>

                    <p>(B) Economic Survey Report of the Society accompanied by an "A" Report of the Society with a recent Trial Balance or Balance Sheet showing the Society's financial position.</p>
                    <p>(C) The Cooperative Society's Bye Law.</p>
                </div>


                <div className="download-form mt-5">
                    <h2>DOWNLOAD REGISTRATION FORM</h2>
                    <p>Having ensured that you have read and agree to the above conditions and procedures and possess all the needed requirements, kindly proceed to download the cooperative registration form and proceed to submit to any of our <span onClick={() => navigate("/about-us/zonal-offices")}>zonal offices.</span> </p>
                    <button className="register-btn btn" onClick={handleDownloadClick}>
                        <Icon icon="mingcute:file-download-fill" className="download-icon" />
                        Download Here
                    </button>
                </div>

            </div>
        </div>
    )
}