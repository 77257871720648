// import ReusableHero from "../../components/reusable/Hero";
// import React, { useState, useEffect } from "react";
// import './publication.css'



// export default function Publication() {


//     const [news, setNews] = useState([]);

//     useEffect(() => {
//         const apiKey = "f3d1da69d1fa45c68f65c9f52594133f";
//         const apiUrl = `https://newsapi.org/v2/top-headlines?country=ng&language=en&apiKey=${apiKey}`;

//         fetch(apiUrl)
//             .then((response) => response.json())
//             .then((data) => {
//                 // Extract the relevant data (image, title, and description)
//                 const articles = data.articles.map((article) => ({
//                     image: article.urlToImage,
//                     title: article.title,
//                     description: article.description
//                 }));
//                 setNews(articles);
//             })
//             .catch((error) => console.error("Error fetching news:", error));
//     }, []);

//     return (
//         <>
//             {/* <ReusableHero /> */}
//             <div className="publication-page">

//                 <div className="d-flex flex-column justify-content-center align-items-center py-5">

//                     <div className="card-section">
//                         {news.map((article, index) => (
//                             <div key={index} className="publication-page-card">
//                                 {article.image && <div className="publication-image"><img src={article.image} alt={article.title} /></div>}

//                                 <div className="publication-info p-2">
//                                      <h2>{article.title}</h2>
//                                 <p>{article.description}</p>
//                                 </div>

//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//         </>

//     )
// }


import React, { useState, useEffect } from 'react';
import './publication.css'
import { Link } from 'react-router-dom';

function NewsComponent() {
    const [news, setNews] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiKey = 'pub_119004a6d8232fc3f67ae7acc9a47f214c634';
                const apiUrl = `https://newsdata.io/api/1/news?apikey=${apiKey}&country=ng&language=en&full_content=1&image=1`;

                const response = await fetch(apiUrl);
                const data = await response.json();
                setNews(data.results);
            } catch (error) {
                console.error('Error fetching news data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="publication-page">
            <div className="d-flex flex-column justify-content-center align-items-center py-5">
                <div className="card-section">
                    {news.map((article, index) => (
                       
                        <Link to={{ pathname: `/publication/${index}`, state: { newsData: news } }} key={index} className="publication-page-card">
                            {article.image_url && (
                                <div className="publication-image">
                                    <img src={article.image_url} alt={article.title} />
                                </div>
                            )}

                            <div className="publication-info p-2">
                                <h2>{article.title}</h2>
                                <p>{article.description}</p>
                            </div>
                        </Link>
                     
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NewsComponent;
