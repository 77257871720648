import React from "react";
import './reusable.css'

export default function TeamCard({ photoSrc, teamName, title }) {
  return (
    <div className="team-card" >
      <div className="team-photo mx-auto small">
        <img src={photoSrc} alt="" />
      </div>
      <p className="team-info small">
        {teamName}
        <br />
        <span>{title}</span>
      </p>
    </div>
  );
}
