import './App.css';
import RoutingPage from './RoutingPage';

function App() {
  return (
    <div className="App">
      
     <RoutingPage />
    </div>
  );
}

export default App;
