const zonalOffices = [
  {
    zonalName: "Yola Zonal Office",
    zonalOffice: "L.G Audit HQTRS Jimeta Yola",
    contactPerson: "Halima Zango",
    phoneNumber: "08036809775",

  },
  {
    zonalName: "Mubi Zonal Office",
    zonalOffice: "Opposite ADSU Mubi",
    contactPerson: "Peter Migada",
    phoneNumber: "07034226579",
  },
  {
    zonalName: "Gombi Zonal Office",
    zonalOffice: "AADP office, Gombi LGA",
    contactPerson: "Ibrahim Joda",
    phoneNumber: "07036589444",
  },
  {
    zonalName: "Numan Zonal Office",
    zonalOffice: "Bange Guest Inn",
    contactPerson: "Titi Obi Dingole",
    phoneNumber: "08165099366",
  },
  {
    zonalName: "Ganye Zonal Office",
    zonalOffice: "Ganye Zonal Office",
    contactPerson: "Mansur Umaru Sanda ",
    phoneNumber: "09130680188",
  },
];

export default zonalOffices;




