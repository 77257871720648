

import React, { useState } from "react";
import "./registration.css";
import MultiPurposeRegistration from "./tabcontent/MultiPurposeRegistration";
import ReusableHero from '../../components/reusable/Hero'



const Registration = () => {
    const [activeTab, setActiveTab] = useState("multi-purpose");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            {/* <ReusableHero /> */}

            <div className="tabs-container p-3">
            <MultiPurposeRegistration />
                {/* <div className="tab-header ">
                    <div
                        className={`tab ${activeTab === "multi-purpose" ? "active" : ""}`}
                        onClick={() => handleTabClick("multi-purpose")}
                    >
                        Multi-purpose Cooperative Society
                    </div>
                    <div
                        className={`tab ${activeTab === "single-purpose" ? "active" : ""}`}
                        onClick={() => handleTabClick("single-purpose")}
                    >
                        Single Purpose Cooperative Society
                    </div>
                </div>
                <div className="tab-content p-3">
                    {activeTab === "multi-purpose" && (
                        <MultiPurposeRegistration />
                    )}
                    {activeTab === "single-purpose" && (
                        <div>
                            <p>This is the content for the single-purpose tab.</p>
                        </div>
                    )}
                </div> */}
            </div>
        </>


    );
};

export default Registration;
