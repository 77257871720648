
import {
  createBrowserRouter,
  Route,
  // Router,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'


//Routes
import RootPage from './pages/root-page/RootPage';
import LandingPage from './pages/home/LandingPage';
import AboutUs from './pages/about/AboutUs';
import Registration from './pages/registration/Registration';
import Publication from './pages/publication/Publications';
import Faq from './pages/faq/Faq';
import ScrollToTop from './components/data/ScrollUpFunction';
import SinglePublicationPage from './pages/publication/SinglePublicationPage';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootPage />}>

      <Route index element={<LandingPage />} />
      <Route path='/home' element={<LandingPage />} />
      <Route path='/about-us' element={<AboutUs />} >
        <Route path='/about-us/team' element={<AboutUs />} />
        <Route path='/about-us/zonal-offices' element={<AboutUs />} />
      </Route>
      <Route path='/registration' element={<Registration />} />
      <Route path='/publication' element={<Publication />} >
        <Route path="/publication/:articleId" element={<SinglePublicationPage />} />
      </Route>

      <Route path='/faqs' element={<Faq />} />



    </Route>
  )
);

function RoutingPage({ user }) {
  return (
    <div>

      <RouterProvider router={router} context={{ user }} >
        <Route element={<ScrollToTop />} />
      </RouterProvider>
    </div>
  );
}
export default RoutingPage