import React, { useState, useEffect } from "react";
import { Link, NavLink, Outlet, useLocation, useNavigate, } from "react-router-dom";
import { Button, Menu, MenuItem } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import logo from '../../assets/logo.png';
import logol from '../../assets/logo-light.png';
import './rootpage.css';
import ReusableHero from "../../components/reusable/Hero";
import { Icon } from '@iconify/react';




export default function RootPage() {

    const scrollToContact = () => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const location = useLocation();

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setClicked(false);
    };

    const handleNavLinkClick = () => {
        alert('Page is yet to be implemented');
        setClicked(false);
    };

    const handleMenuEnter = (event) => {
        handleMenuOpen(event);
    };

    const handleMenuLeave = () => {
        // Close the menu when moving the mouse away
        handleMenuClose();
    };

    const [clicked, setClicked] = useState(false);
    const handleClick = () => {
        setClicked(!clicked);
    };

    const closeMobileMenu = () => setClicked(false);

    return (
        <div className="root-page" id="top-section">
            <header className="container-fluid m-0 px-5 py-2 header">

                <div className="d-flex align-items-center justify-content-between nav-bar">
                    <div className="d-flex align-items-center logo">
                        <img src={logo} alt="logo image" onClick={() => navigate("/")} />
                        <h1>ADAMAWA STATE {<br />} DEPARTMENT OF {<br />} COOPERATIVES </h1>
                    </div>

                    <div className="toggle">
                        <div className={`xx ${clicked ? "active nav-links" : "nav-links"}`} id="navlinks">
                            <Link to="/home" className="nav-item" activeClassName="active-link" onClick={closeMobileMenu}>
                                Home
                            </Link>
                            <div
                                // onMouseEnter={handleMenuEnter} 
                                onMouseLeave={handleMenuLeave}
                            >
                                <Button
                                    className="nav-item abt"
                                    // component={NavLink}
                                    activeClassName="active-link"
                                    onClick={handleMenuOpen}
                                    // onMouseEnter={handleMenuEnter}
                                    endIcon={<ExpandMoreRoundedIcon />}
                                    aria-controls="about-us-menu"
                                    aria-haspopup="true"

                                >
                                    About Us
                                </Button>
                                <Menu
                                    id="about-us-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem className="nav-item" onClick={handleMenuClose} component={NavLink} to="/about-us/zonal-offices"  >
                                        Zonal Offices
                                    </MenuItem>
                                    <MenuItem className="nav-item" onClick={handleMenuClose} component={NavLink} to="/about-us/team">
                                        Team
                                    </MenuItem>
                                </Menu>
                            </div>
                            <Link to="/registration" className="nav-item" activeClassName="active-link" onClick={closeMobileMenu}>
                                Cooperative Registration
                            </Link>
                            <Link onClick={handleNavLinkClick} className="nav-item" activeClassName="active-link">
                                Publications
                            </Link>
                            <Link to="/faqs" className="nav-item" activeClassName="active-link" onClick={closeMobileMenu}>
                                FAQs
                            </Link>
                            <Link to="/home" className="nav-item" activeClassName="active-link" onClick={() => {
                    closeMobileMenu();
                    scrollToContact(); // Scroll to Contact Us section
                }}>
                                Contact Us
                            </Link>
                        </div>


                    </div>
                    <div id="mobile" onClick={handleClick}>

                        <Icon icon={clicked ? "gg:close" : "gg:menu-right-alt"} className='bar' />



                    </div>

                </div>

            </header>


            <main className="mt-4" >
                {location.pathname !== '/publication/:articleId' && <ReusableHero />}
                <Outlet />
            </main>




            <footer>
                <div className="footer-nav py-4">
                    <div className="d-flex align-items-start justify-content-around my-5 footer-content">
                        <div className="d-flex align-items-center logo">
                            <img src={logol} alt="logo image" onClick={() => navigate("/")} />
                            <h1>ADAMAWA STATE {<br />} DEPARTMENT OF {<br />} COOPERATIVES </h1>
                        </div>

                        <div className="d-flex links">

                            <div className="d-flex my-footer-li mx-4">
                                <div className="zonal footer-links">
                                    <p>Zonal Offices</p>
                                    <ul>
                                        <li><a>Numan</a></li>
                                        <li><a>Mubi</a></li>
                                        <li><a>Gombi</a></li>
                                        <li><a>Ganye</a></li>

                                    </ul>
                                </div>

                                <div className="zonal footer-links">
                                    <p>Quick links</p>
                                    <ul>
                                        <li><a>Publication</a></li>
                                        <li><a>Registration</a></li>
                                        <li><a>Our Team</a></li>
                                        <li><a>Registration Procedure</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="zonal footer-links">
                                <p>Adamawa Coops HQ</p>
                                <div className="d-flex flex-column">
                                    <span className="location">Ministry For Entrepreneurship Development, Office of the Deputy Governor, No. 1 Adamawa Road, off Kashim Ibrahim Way, P.M.B 2097, Jimeta Yola, Adamawa State</span>
                                    <span className="mt-3">adamawacoop@gmail.com</span>
                                    <span className="mt-3">+234 (0)8146205677</span>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="d-flex justify-content-center">
                        <span className="copyright ">Copyright © 2023 adamawa coops | All Rights Reserved</span>
                    </div>
                </div>
            </footer>
        </div>
    )
}