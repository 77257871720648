import { Icon } from '@iconify/react';
import "./faq.css";
import React, { useState } from 'react';
import ReusableHero from '../../components/reusable/Hero';

function Accordion(props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [iconToggle, setIconToggle] = useState("");

    const { title, content, isOpen, onToggle } = props;

    const handleToggle = () => {
        // setIsExpanded(!isExpanded);
        // setIconToggle(!iconToggle);
        onToggle();
    }

    const contentWithLineBreaks = { __html: props.content };

    return (

        <div className={`accordion ${isOpen ? "expand" : ""}`}>
            <div className="accordion-header" onClick={handleToggle}>
                <div>
                <Icon icon={!isOpen ? "ph:plus-circle-fill" : "ph:minus-circle-fill"} className='faq-icons' />
                </div>
                {/* <Icon icon={!isExpanded ? "ph:plus-circle-fill" : "ph:minus-circle-fill"} className='faq-icons' /> */}
                <p>
                    {props.title}
                </p>


            </div>
            {isOpen && (
                <div className="accordion-content">
                    <p dangerouslySetInnerHTML={contentWithLineBreaks}>
                        {/* {props.content} */}
                    </p>

                </div>
            )}
        </div>


    );
}

function Faq() {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

    const accordionData = [
        {
            title: "What is Adamawa State Department of Cooperatives (ASCP)?",
            content: "The Adamawa State Department of Cooperatives is a government establishment that helps people with limited means come together voluntarily to create Cooperative Societies, aiming to achieve common economic goals.",
        },
        {
            title: "How long has cooperative practice been a part of Adamawa State?",
            content: "Cooperative practice has been present in Adamawa State for a very long time and is deeply rooted in the history and culture of the people.",
        },
        {
            title: " What is the mission of Adamawa State Department of Cooperatives?",
            content: "The mission is to organize programs that promote unity, knowledge acquisition, and the well-being of members. Additionally, it represents the cooperative sector in discussions with the government and collaborates with development partners.",
        },
        {
            title: "What is the vision of Adamawa State Department of Cooperatives?",
            content: "The vision is to become a leading non-governmental organization dedicated to enhancing the economic empowerment and prosperity of Adamawa State.",
        },
        {
            title: "How does the cooperative registration process work?",
            content: "Cooperative Inspectors visit communities to raise awareness about forming cooperative societies. Interested groups elect officials under the inspector's supervision. The inspector writes reports and, after review, recommends the society for registration. The Director of Cooperatives then issues a Certificate of Registration.",
        },
        {
            title: "How can I register a cooperative society?",
            content: "To register a cooperative society, you need at least ten members. Download the registration form from the website, fill it out accurately, and submit it to the office. Make sure to include details about the society's name, members, objectives, and other necessary information.",
        },
        {
            title: "What documents are required for registration?",
            content: "You'll need:<br />- An application form with society details<br />- Economic Survey Report with financial position<br />- Cooperative Society's Bye Law outlining rules and guidelines",
        },
        {
            title: "Can you provide the eligibility criteria for registering a cooperative society?",
            content: "To be eligible for registration, your cooperative society must have at least ten members. You'll need to provide information about the society's name, location, objectives, and contact details for the President and Secretary. Also, include the aims and objectives of the proposed society.",
        },


    ];

    const handleAccordionToggle = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
      };

    return (
        <>
            {/* <ReusableHero /> */}
            <div className="faq p-4">
                <p className='faq-p'>Certainly, here are eight FAQs (Frequently Asked Questions) based on the information you provided about the Adamawa State Department of Cooperatives:</p>
                <div className="d-flex justify-content-center align-items-center my-3 p-5">


                    <div className="faq-list p-0">

                        {accordionData.map((data, index) => (
                            <Accordion key={index} title={data.title} content={data.content} isOpen={openAccordionIndex === index}
                            onToggle={() => handleAccordionToggle(index)} />
                        ))
                        }
                    </div>

                </div>
            </div>
        </>

    );
}

export default Faq;
